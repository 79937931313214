@import "@styles/helpers/variables";
@import "@styles/components/elements/button.module.scss";

.ctaBtn {
  @extend .btn;
  font-weight: bold;
  padding: 8px 30px 10px;

  &.defaultMargin {
    margin: 1rem;
  }

  span {
    margin-left: 10px;
  }

  @media only screen and (min-width: $large) {
    font-size: 1.5rem;
  }
}
